import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { includes } from 'lodash';
import { PageTypes } from '@sparefoot/segment-react';
import { SegmentRegion } from '@sparefoot/react-core/segment';
import { getErrorViewPixel } from '@sparefoot/react-core/utils/tracking';

import { withSearchHandler } from 'decorators';
import { SearchBox } from 'components/search/SearchBox';
import { Page, Container } from 'components/core/Page';
import { H1, Paragraph } from 'components/core/Typography';
import Status from 'utils/routes/Status';

import './ErrorPage.scss';

export const enhance = withSearchHandler;

export function ErrorPage({ statusCode, message, handleSearch, redirectURL }) {
	return (
		<Status
			code={statusCode}
			redirectURL={redirectURL}
		>
			<Page
				className="error-page"
				trackingPixel={getErrorViewPixel(`error${statusCode}`)}
				pageType={PageTypes.ERROR}
				title={
					statusCode === 404
						? '404 Page Not Found - SelfStorage.com'
						: `${statusCode} Error - SelfStorage.com`
				}
			>
				{includes([404, 410], statusCode) && (
					<Container className="error-404">
						<SegmentRegion segmentCategory="search interactions">
							<div className="search-wrapper">
								<H1
									size="mega"
									color="white"
								>
									{statusCode} - Oops!
									<br />
									Nothing here!
								</H1>
								<SearchBox onSearch={handleSearch} />
							</div>
						</SegmentRegion>
					</Container>
				)}
				{!includes([404, 410], statusCode) && (
					<Container>
						<H1 size="large">{statusCode} Error</H1>
						<Paragraph>{message}</Paragraph>
					</Container>
				)}
				{includes([301, 302], statusCode) && redirectURL && (
					<Redirect to={redirectURL} />
				)}
			</Page>
		</Status>
	);
}

ErrorPage.propTypes = {
	statusCode: PropTypes.number,
	message: PropTypes.string,
	handleSearch: PropTypes.func,
	redirectURL: PropTypes.string,
};

ErrorPage.defaultProps = {
	statusCode: 404,
	message: 'page not found',
};

export default enhance(ErrorPage);
