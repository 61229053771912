import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

export default function Status({ code, redirectURL, children }) {
	return (
		<Route
			render={({ staticContext }) => {
				if (staticContext) {
					staticContext.status = code;
				} // eslint-disable-line no-param-reassign
				if (staticContext && redirectURL) {
					staticContext.redirectURL = redirectURL; // eslint-disable-line no-param-reassign
				}
				return children;
			}}
		/>
	);
}

Status.propTypes = {
	code: PropTypes.number.isRequired,
	redirectURL: PropTypes.string,
	children: PropTypes.node,
};
